.toggle-transition{
    transition: all 0.8s;
}

.toggle-transition.sidebarinactive{
    width: 0%;
}

.toggle-transition.sidebarinactive .side-bar-wrapper{
    display: none;
}

.toggle-transition.dashboardwidthinactive{
    width: 100%;
}

.dash-content-wrapper{
    padding: 24px 24px 0;
}

.top-bar-fix{
    box-shadow: 0px 4px 16px 4px #F4F6F8;
    background: #FFFFFF;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 9;
}

.top-bar-fix .top-bar-content-wrp{
    display: flex;
    align-items: center;
    gap: 20px;
}

.top-bar-fix .top-bar-content-wrp button{
    border: none;
    background: none;
    padding: 0;
}

.top-bar-fix .top-bar-content-wrp span img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: 50px;
}

.side-bar-wrapper{
    height: 100vh;
    box-shadow: 0px 4px 16px 4px #F4F6F8;
    background: #FFFFFF;
    position: fixed;
    width: 16.66666667%;
    z-index: 99;
    padding: 32px 16px;
    text-align: center;
}

.side-bar-wrapper nav{
    margin-top: 20px;
}

.side-bar-wrapper nav .nav-item{
   margin-bottom: 8px;
}

.side-bar-wrapper nav .nav-item a{
    text-align: left;
    text-align: left;
    padding: 12px 16px !important;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #78808D;
    background: #fff;
    border: 1px solid #fff;
    transition: all 0.8s;
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
}

.side-bar-wrapper nav .nav-item a.active img,
.side-bar-wrapper nav .nav-item a:hover img{
    filter: invert(62%) sepia(41%) saturate(4900%) hue-rotate(200deg) brightness(103%) contrast(107%);
}


.side-bar-wrapper nav .nav-item a:hover,
.side-bar-wrapper nav .nav-item a.active{
    color: #344054; 
    background: #CCE5FF;
    border: 1px solid #AAD3FF;
}

.side-bar-wrapper nav .navbar-collapse, .side-bar-wrapper nav .navbar-collapse .navbar-nav{
    width: 100%;
}

.table-page-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.table-page-top h2{
font-size: 24px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0.10000000149011612px;
color: #344054;
margin-bottom: 0;
}

.table-wrapper table td,
.table-wrapper table th{
    border-color: #F1F2F4;
    padding: 17px 20px ;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    background: transparent;
}

.table-wrapper table td{
    color: #344054;
    font-weight: 400;
    cursor: pointer;
    transition: font-weight 0.3 ease-out;
}

.table-wrapper table th{
    color: #78808D;
    font-weight: 700;
}

.button-primary{
background: #007BFF !important;
padding: 12px 16px !important;
border-radius: 12px;
color: #fff;
font-size: 14px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0.10000000149011612px;
border: none;
}

.button-primary:hover{
    box-shadow: none;
    background-color: #046dde !important;
}

.button-primary svg{
    width: 30px;
    height: 30px;
    color: #fff;
}

.table-wrapper{
    padding: 24px;
    border-radius: 24px 24px 0 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 4px #F4F6F8;
    margin-top: 48px;
    overflow: auto;
}

.serach-ontable{
border: 1px solid #F4F6F8;
border-radius: 50px;
padding: 12px;
width: 400px;
margin-bottom: 16px !important;
}

.serach-ontable:focus{
    box-shadow: none;
}

.serach-ontable input,
.serach-ontable span{
    border: none;
    background: none;
    padding: 0px;
}

.serach-ontable span svg{
color: #BBBFC6;
margin-right: 8px;
}

.serach-ontable input{
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0.10000000149011612px;
color: #000;
}

.serach-ontable input::placeholder{
opacity: 1;
color: #BBBFC6;
}

.content-top-heading{
    color: #344054;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    margin-bottom: 16px;
}

.packg-list-wrapper{
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    overflow: auto;
    padding-bottom: 6px;
}

.packg-list-wrapper .package-cr{
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #CCE5FF;
    border-radius: 12px;
    padding: 12px;
}

.packg-list-wrapper .package-cr .package-left-ico{
    width: max-content;
}

.packg-list-wrapper .package-cr .package-right-cnt{
    display: flex;
    gap: 24px;
    align-items: center;
}

.packg-list-wrapper .package-cr .package-right-cnt button{
    background: #007BFF;
    padding: 5px 8px;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    border: none;
}

.packg-list-wrapper .package-cr .package-right-cnt span{
    width: max-content;
}

.packg-list-wrapper .package-cr .package-right-cnt span p{
    margin: 0;
    color: #78808D;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
}

.packg-list-wrapper .package-cr .package-right-cnt span h5{
    margin: 0;
    color: #007BFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.10000000149011612px;
}


.pop-up-step{
    text-align: center;
}

.pop-up-step .pop-step-cntnt{
   margin: 16px 0;
}

.pop-up-step .pop-step-cntnt h5{
    margin-bottom: 12px;
    color: #344054;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.10000000149011612px;
}

.pop-up-step .pop-step-cntnt p{
    margin-bottom: 0px;
    color: #78808D;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;    
}

.checklist-img-dul{
    opacity: 1 !important;
    filter: brightness(0.9);
}

.pop-up-step img{
    opacity: 0.9;
}

.pop-step-tabelcntnt{
   /* // margin: 24px 0 0; */
    padding: 12px;
    border-radius: 12px;
    background: #F8F9FB;
    text-align: left;
}

.pop-step-tabelcntnt .tittle{
    color: #344054;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    margin-bottom: 0;
}

.popup-table{
  margin-bottom: 0;  
  margin-top: 24px;
}

.popup-table th{
  color: #344054;
font-size: 14px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0.10000000149011612px;
width: 25%;
background: inherit;

}

.popup-table td{
    color: #344054;
font-size: 14px;
font-weight: 400;   
line-height: 22px;
background: inherit;
letter-spacing: 0.10000000149011612px;
}

.popup-table td a{
 padding: 0;
 background: none;
 text-decoration: none;
 color: #007BFF;
}

.popup-table tr{
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
}

.popup-table tr:last-child{
    padding-bottom: 0px;
}
.offender-table th {
    width: 50% !important;
}
.offender-table td {
    width: 50% !important;
}
.offender-table th, .offender-table td {
    padding: 15px;
    text-align: center !important;
    word-wrap: break-word;
    border-bottom: 1px solid #ddd;
}

.burgur-menu-wrap{
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 4px #F4F6F8;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.change-avatar-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;
}

.change-avatar-sec img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
}

.change-avatar-sec span{
    margin: 0;
    padding: 0 !important;
    background: none;
    border: none;
    color: #007BFF !important;
    font-weight: 700;
    cursor: pointer;
}

ul.nav-tabs{
    margin-bottom: 24px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    border: none;
}

ul.nav-tabs li.nav-item{
    width: 100%;
}

ul.nav-tabs li.nav-item button{
    width: 100%;
    padding: 10px 0;
    border: none;
    color: #78808D;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
}

ul.nav-tabs li.nav-item button.active{
    color: #344054;
    border-bottom: 4px solid #007BFF;
    font-weight: 700;
}

.tabs-form-top-heading-line{
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    color: #344054;
    margin-bottom: 16px;
}

.payment-addstripe-erapper{
    min-height: 250px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.payment-addstripe-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;  
}

.payment-addstripe-wrapper img{
    width: 144px;
}

.payment-addstripe-wrapper button{
    border: 1px solid #007BFF;
    border-radius: 12px;
    padding: 5px 8px;
    background: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #007BFF;
    transition: all 0.8s;
    }

.payment-addstripe-wrapper button:hover{
    color: #fff;
    background: #007BFF;
}

.pagination-btm-tablecustom{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
}

.pagination-btm-tablecustom p{
    margin-bottom: 0;
    font-size: 14px;
    color: #344054;
}

.pagination-btm-tablecustom button{
    background: none;
    border: none;
    padding: 0;
}

.pagination-btm-tablecustom .select-withbx{
    display: flex;
    gap: 10px; 
    align-items: center;  
    flex-wrap: wrap;
}

.pagination-btm-tablecustom .select-withbx select{
    width: auto;
    border: none;
    padding: 10px 28px 10px 0;
}

.pagination-btm-tablecustom .select-withvalue{
    display: flex;
    gap: 10px; 
    align-items: center;    
}

.select-witharrow{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 16px;    
}

.data-not-found{
    font-weight: 600;
    font-style: italic;
    color: #3a6990;
}

.popup-sex-offender-customer-header{
    justify-content: center;
    margin-bottom: 1rem;
}

table.customerDetails tbody tr:hover{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    font-weight: 600;
}

table.customerDetails tbody tr:hover td{
    font-weight: 500;
}

/* NEW */
.dashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.guest-card {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.average-rating-text{
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
}





.chart-container {
    width: 100%;
    height: 400px;
}

.stats {
    color: #666;
}
.card.average-rating h4{
 
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    margin-top: 2px;
    
}
.star-with-rating h3 {
    font-weight: 700;
    font-family: Poppins;
    font-size: 57px;
    line-height: 48px;
    letter-spacing: 4.1px;
    text-align: left;
    -webkit-text-fill-color: #e6a015c2;
    padding-right:5px ;
}
.star-with-rating {
   display: flex;
    
}

.star-with-rating span{
    width: 17.11px;
    height: 16.36px;
    top: 3.82px;
    left: 3.44px;
    gap: 0px;
    -webkit-text-fill-color: #007BFF;
    font-size: 22px;
    font-weight: 600;
    padding-left: 4px;
  
     
 }
.last-month{
font-family: "Poppins";
font-size: "14px";
font-weight: "400";
line-height: "22px";
letter-spacing: "0.10000000149011612px";
text-align: "left";
}
.last-month span{
 color: #007BFF;
    }
    .card.chart {
        display: flex;
    }
  .card.chart h6{
 
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    
    }

    .card.chart span{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        }
