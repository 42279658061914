.card-sucess{
    display: flex;
    justify-content: center;
}

.tick-machline{
    display: flex;
    justify-content: center;
}


.card h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.card p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    margin: 0;
}

i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.card {
    background: white;
    padding: 30px 60px 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}

.green-bx-scs .card{
    box-shadow: 0 2px 3px #88B04B;
}

.red-failed .card{
    box-shadow: 0 2px 3px #c33131;
}

.red-failed .card h1{
    color: #c33131;
}

.red-failed .card i {
    color: #c33131;
}

.red-failed .tick-machline{
    background: rgb(250 245 245);
}