


.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.tab.active {
    background-color: #007bff;
    color: #fff;
}

.tab:hover {
    background-color: #007bff;
    color: #fff;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f8f9fc;
}

.view-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.view-button:hover {
    background-color: #0056b3;
}
