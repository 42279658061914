.forgot-password-screen{
    padding-top: 25px;
    padding-bottom: 25px;
    height: 100vh;
}

.forgot-password-screen .row{
    height: 100%;
}

.animation-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-password-left{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.forgot-password-left .building {
    width: 59%;
    animation: moveIt 4.5s ease-in-out infinite;
}

.forgot-password-left .sun {
    position: absolute;
    right: 160px;
    top: 50px;
    animation: moveItt 4.5s ease-in-out infinite;
    width: 100px !important;
}

@keyframes moveIt {
    0%, 100% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(-10deg);
    }
}

@keyframes moveItt {
    0%, 100% {
        right: 260px;
        top: 50px;
    }
    50% {
        right: 170px;
        top: -55px;
    }
}

.forgot-password-left .lock {
    width: 50%;
}

.forgot-password-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    padding: 24px;
    background: #fff;
    box-shadow: 0 0 8px #c3c2c20d;
    justify-content: center;
    height: 100%;
    position: relative;
}

.forgot-password-right .logo{
    position: fixed;
    top: 0;
}

.login-signup-form-btm-sec-dv{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
}

.login-signup-form-btm-sec-dv .account-or-forgot{
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    padding: 9px;
    border-radius: 52px;
    line-height: 21px;
    width: 40px;
    height: 40px;
}

.forgot-password-right .sign-up-fotm{
    width: 100%;
}

.forgot-password-right .sign-up-fotm h2{
    color: #344054;
    font-size: 32px;
    font-weight: 700;
}

.forgot-password-right .sign-up-fotm p{
    color: #78808D;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}

.forgot-password-right .sign-up-fotm p.have-an-account{
    margin-bottom: 0;
    text-align: center;
    color: #78808D;
    font-size: 14px;
    font-weight: 400;
}

.forgot-password-right .sign-up-fotm p.have-an-account a,
.forgot-password-right .sign-up-fotm p.have-an-account span{
    color: #007BFF !important;
    font-weight: 500;
    text-decoration: none;
}

.forgot-password-right .sign-up-fotm p.have-an-account.have-an-account-otp{
    font-weight: 700;
    color: #BBBFC6;
}

.verify-left .logo{
    left: 20px;
}

.forgot-password-left .key {
    position: absolute;
    right: 90px;
    top: 80px;
    animation: moveIttt 4.5s ease-in-out infinite;
    width: 160px !important;
}
@keyframes moveIttt {
    0%, 100% {
    right: 90px;
    top: 80px;
    transform: rotate(0deg);
    
    
    }
    50% {
        right: 150px;
        top: 200px;
        transform: rotate(60deg);
    }
}

.forgot-password-form-wrp{
    margin: 24px 0;
}

.forgot-password-form__description{
    font-size: 0.7rem;
}