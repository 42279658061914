.wrap-search{
        display: flex;
}
.wrap-search button {
    height: 37px;
    margin-top: 5px;  
}

.clenLess-star-rating-sec, .adherence-star-rating-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-responsive-lg table.table tbody tr td button {
    border: 1px solid #007bff;
    background: #fff;
    color: #007bff;
}

.table-responsive-lg table.table tbody tr td button:hover{
    border: 1px solid #007bff;
    background: #007bff;
    color: #fff;
}
.overlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
